
import { defineComponent, reactive } from 'vue';

export default defineComponent({
  name: 'SecurityText',
  setup(props, { emit }) {
    const state = reactive({});

    return {
      state,
    };
  },
});
